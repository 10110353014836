@use "../../assets/styles/settings/variables" as *;

.banner__contact {
	background-color: var(--background-common);

	@media (min-width: $lg) {
		background-color: transparent;
	}

	.container__banner {
		position: relative;
		padding: 127px 0 197px;
		text-align: center;
		background-color: transparent;

		@media (min-width: $lg) {
			padding: 146px;
			background-color: var(--background-common);
		}

		.title__banner {
			color: var(--title-common);
			width: 90%;
			margin: 0 auto;

			@media (min-width: $lg) {
				width: 68%;
				margin: 0 auto;
			}
		}

		.button {
			margin-top: 40px;

			@media (min-width: $lg) {
				margin-top: 60px;
			}
		}

		.lines__bottom {
			position: absolute;
			bottom: 25px;
			left: 30px;
			max-width: 80px;
			height: auto;

			@media (min-width: $lg) {
				bottom: 40px;
				left: 50px;
				width: auto;
				max-width: 140px;
			}
		}

		.lines__top {
			position: absolute;
			top: 0;
			right: 10px;
			max-width: 80px;
			height: auto;

			@media (min-width: $lg) {
				width: auto;
				max-width: 140px;
			}
		}
	}
}