@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@300;400;600&family=Comfortaa:wght@300;400;500;600;700&family=Inter:wght@300;400;600;800&display=swap');

$font-family-title: 'Comfortaa', sans-serif;
$font-family: 'Inter', sans-serif;
$font-family-subtitle: 'Anek Telugu', sans-serif;


// Typography scale
$fs-base: 16;

$_fs-13: calc(13 / $fs-base) + rem;
$_fs-14: calc(14 / $fs-base) + rem;
$_fs-16: calc(16 / $fs-base) + rem;
$_fs-18: calc(18 / $fs-base) + rem;
$_fs-20: calc(20 / $fs-base) + rem;
$_fs-24: calc(24 / $fs-base) + rem;
$_fs-36: calc(36 / $fs-base) + rem;
$_fs-40: calc(40 / $fs-base) + rem;
$_fs-64: calc(64 / $fs-base) + rem;

// // Typography applications
$fs-body: $_fs-18;
$fs-body-s: $_fs-16;
$fs-body-xs: $_fs-14;
$fs-body-xxs: $_fs-13;

$fs-title-l: $_fs-64;
$fs-title-m: $_fs-40;
$fs-title: $_fs-36;

$fs-sub-title-m: $_fs-24;
$fs-sub-title: $_fs-20;
