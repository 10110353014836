@use '../../assets/styles/settings/variables' as *;

.home__team{
  background-color: var(--background-common);
  padding-top: 120px;
  padding-bottom: 90px;
  margin-bottom: 60px;

  @media (min-width: $lg) {
    margin-bottom: 120px;
  }

  .team__wrapper {
    display: none;

    @media (min-width: $lg) {
      display: block;
    }
  }

  .swiper-slide:nth-child(1){
    margin-left: 2rem;
  }

  .team__wrapper:nth-of-type(3),
  .swiper-slide:nth-child(2) {
    margin-top: 80px;
  }

  .team__wrapper:nth-of-type(4),
  .swiper-slide:nth-child(3) {
    margin-top: 50px;
  }

  .team__wrapper:nth-of-type(5),
  .swiper-slide:nth-child(4) {
    margin-top: 30px;
  }

  .swiper {
    overflow: visible;
    display: flex;

    @media (min-width: $lg) {
      display: none;
    }
  }

  .card__img {
    filter: grayscale(30%)
  }

  .button--common {
    margin-top: 60px;
    
    @media (min-width: $lg) {
      margin-top: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--primary-common-color);
  }

  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)  {
    background: var(--text-common);
    opacity: 0.6;
  }
}
