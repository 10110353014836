@use "../../assets/styles/settings/variables" as *;

.card__col {
	display: none;

	@media (min-width: $lg) {
		display: flex;
	}
}

.card__col:nth-of-type(1) {
	display: flex;
}

.card__col:nth-of-type(2) {
	display: flex;
}

.card__wrapper {
	margin-top: 75px;
	width: 100%;

	@media (min-width: $lg) {
		margin-top: 95px;
	}

	.card__link {

		&:hover,
		&:focus {
			color: var(--text-color);
		}
	}

	.card__service {
		height: 100%;
		position: relative;
		box-shadow: 0px 16px 30px rgba(5, 26, 57, 0.04);
		border-radius: 4px;
		text-align: center;
		padding: 60px 0 24px;
		background-color: var(--card-background);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.icon__service {
			display: grid;
			place-items: center;
			background-color: var(--secondary-color);
			width: 70px;
			height: 70px;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: 50%;
			transform: translate(50%, -50%);

			img {
				width: auto;
				height: auto;
			}
		}

		.title__service {
			font-family: $font-family-title;
			font-weight: 600;
			margin: 0;
			margin-bottom: 20px;
		}

		.text__service {
			font-weight: 300;
			font-size: 16px;
			width: 74%;
			margin: 0 auto;
		}

		.link__service {
			display: grid;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			border: 1.5px solid var(--link-border);
			border-radius: 50%;
			margin-left: auto;
			transition: all 200ms ease-in-out;
			color: var(--link-arrow);
			margin-top: 24px;
			margin-right: 28px;

			@media (min-width: $lg) {
				margin-top: 54px;
				margin-right: 24px;
			}

			&:hover {
				border: none;
				background-color: aquamarine;
			}
		}
	}
}

.app-wrapper.dark {
	.icon--it {
		content: url("https://onvia.es/img/icons/it-icon-pri.svg");
	}

	.icon--web {
		content: url("https://onvia.es/img/icons/web-icon-pri.svg");
	}

	.icon--bpm {
		content: url("https://onvia.es/img/icons/bpm-icon-pri.svg");
	}

	.icon--dev {
		content: url("https://onvia.es/img/icons/dev-icon-pri.svg");
	}

	.icon--sell {
		content: url("https://onvia.es/img/icons/selligent-icon-pri.svg");
	}

	.icon--support {
		content: url("https://onvia.es/img/icons/it-icon-pri.svg");
	}

	.icon--bi {
		content: url("https://onvia.es/img/icons/bi-icon-pri.svg");
	}
}