:root {
  --deep-cove: #051A39;
  --aquamarine: #46FAD9;
  --turquoise: #3AE4C5;
  --wild-sand: #F6F6F6;
  --silver: #C4C4C4;
  --downriver: #081F41;
  --alabaster: #FCFCFC;
  --white: #fff;
  --turquoise-dark-mode: #35DCBD;
  --dark-sections: #081f41;
}