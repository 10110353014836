@use "../../assets/styles/settings/variables" as *;

section.error-404 {
  text-align: center;

  .error__number {
    color: var(--secondary-color);
    font-weight: 900;
    font-size: 8rem;
    line-height: 1;
    margin-top: 4rem;

    @media (min-width: $lg) {
      font-size: 12rem;
    }
  }

  h4, .button {
    margin-bottom: 4rem;
  }
}