@use "../../assets/styles/settings/variables" as *;

.services__list {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  background-color: transparent;
  transition: background-color ease-in-out 300ms;
  margin-top: 40px;
  flex-wrap: wrap;
  z-index: 1;

  .list__item {
    background: var(--text-common);
    border: 1px solid var(--link-solid-text);
    border-radius: 100px;
    font-size: $fs-body-xxs;
    font-weight: 500;
    margin-right: 12px;
    margin-bottom: 24px;
    transition: ease-in 300ms;
    white-space: nowrap;
    cursor: pointer;
    width: 92px;
    text-align: center;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      color: var(--link-solid-text);
      padding: 9px 8px 7px 8px;
      display: inline-block;
      border-radius: 100px;
      width: 100%;

      &:hover {
        color: var(--link-solid-text);
      }

      &.active {
        background: var(--link-solid-background);
      }
    }

    &:hover {
      background: var(--link-solid-background);
      border: 1px solid var(--link-solid-background);
    }
  }

  &.sticky {
    position: fixed;
    width: 100%;
    top: 108px;
    background-color: var(--background-common);
    margin-top: 0;

   @media (min-width: $lg) {
    top: 112px;
   }

    .list__item {
      border: none;
    }
  }
}