@use "../../assets/styles/settings/variables" as *;

.main-header {
  width: 100%;
  font-size: $fs-body-s;
  padding-block: 1.75rem;
  position: relative;
  z-index: 5;

  .main-header__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-wrapper__burguer-menu {
    display: block;
    cursor: pointer;

    @media (min-width: $lg) {
      display: none;
    }
  }

  .header-wrapper__logo-light {
    width: 143px;
    height: 52px;
  }

  .header-wrapper__menu-list {
    display: none;

    @media (min-width: $lg) {
      display: flex;
      padding-left: 1rem;
    }
  }

  .language-select {
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    margin-right: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--text-color);

    &:focus-visible,
    &:focus {
      outline: none;
    }

    option {
      text-transform: uppercase;
    }
  }

  .menu-list__item {
    margin-right: 16px;
    position: relative;
    text-shadow: none;

    @media (min-width: $xl) {
      margin-right: 30px;
    }

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      background-color: var(--primary-common-color);
      border-radius: 50%;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }

    &:hover,
    &.active {
      color: var(--secondary-color);
      text-shadow: 0 0 0.05em var(--secondary-color);

      &::after {
        display: block;
      }
    }
  }

  .header-wrapper__buttons-wrapper {
    display: flex;
    align-items: center;

    .button--solid {
      display: none;

      @media (min-width: $lg) {
        display: block;
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    z-index: 2;
    background-color: var(--card-background);
  }
}

.dark {
  .header-wrapper__logo-light {
    content: url(../../assets/images/logo-dark.png);
  }

  .language-select {
    background-color: var(--primary-color);
  }
}
