@use '../../assets/styles/tools/justify' as *;
@use '../../assets/styles/settings/variables' as *;

.title__wrapper {
  @include justify(center, center);
}

.title__section {
  color: var(--text-color);
  text-align: center;
  font-size: 2.25rem;
  
  @media(min-width: $lg) {
    font-size: 2.5rem;
  }

  &.common {
    color: var(--text-common);
  }

  span {
    color: var(--title-common);
  }

}