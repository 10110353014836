@use "../../assets/styles/settings/variables" as *;

.modal-header {
  border: none;
  padding: 0;
  padding-bottom: 24px;

  .btn-close {
    background: transparent;
    opacity: 1;

    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }

    &:hover {
      opacity: 0.6;
    }

    svg {
      color: white;
    }
  }
}

.modal-content {
  background-color: transparent;
  border: none;

  iframe {
    width: 100%;
    max-width: 100%;
    height: 400px;

    @media (min-width: $lg) {
      height: 600px;
    }
  }

  .modal-body {
    padding: 0;
  }
}
