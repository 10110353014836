@use '../../assets/styles/settings/variables' as *;

.team__card {
  margin-top: 40px;
  margin-bottom: 60px;

  .card__figure {
    .card__img {
      width: 100%;
      height: auto;
      border-radius: 4px;
      aspect-ratio: 7/8;
      object-fit: cover;
    }
  }

  .card__header {
    .card__title {
      color: var(--title-common);
      font-size: $fs-sub-title;
    }

    .card__subtitle {
      color: var(--text-common);
      font-family: $font-family-subtitle;
      font-weight: 300;
      font-size: $fs-body-s;
    }
  }
}