@use '../../assets/styles/settings/variables' as *;

.about-us__team {
  margin-bottom: 90px;

  .team__card {
    margin-top: 20px;
    margin-bottom: 0;

    @media (min-width: $lg) {
      margin-top: 68px;
    }
  }

  .title__wrapper {
    margin-bottom: 22px;
  
    @media (min-width: $lg) {
      margin-bottom: 0;
    }
  }

  .team__wrapper {
    padding: 15px;
    
    // @media (min-width: $lg) {
    //   padding: 3rem 3rem 0;
    // }

    .card__img {
      filter: grayscale(30%)
    }
  }

  .team__wrapper:nth-of-type(4n + 2) {
    margin-top: 0;

    @media (min-width: $lg) {
      margin-top: 60px;
    }
  }

  .team__wrapper:nth-of-type(4n + 3) {
    margin-top: 0;

    @media (min-width: $lg) {
      margin-top: 30px;
    }
  }

  .team__wrapper:nth-of-type(4n + 4) {
    margin-top: 0;

    @media (min-width: $lg) {
      margin-top: 10px;
    }
  }

  .card__header {
    .card__title {
      color: var(--secondary-color);
    }

    .card__subtitle {
      color: var(--text-color);
    }
  }
}