@use "../../assets/styles/settings/variables" as *;

.about-us__text__image {
	margin-top: 46px;
	margin-bottom: 60px;
	filter: grayscale(30%);

	@media (min-width: $xl) {
		margin-bottom: 120px;
	}

	.section__title {
		font-size: 2.25rem;
		margin-top: 31px;
		margin-bottom: 12px;
  
		@media (min-width: $lg) {
			font-size: 2.5rem;
			margin-top: 0;
			margin-bottom: 20px;
		}
	}

	.section__text {
		width: 100%;

		@media (min-width: $lg) {
			width: 96%;
		}
	}
}
