@use '../../assets/styles/settings/variables' as *;

.home-text__image {
	margin-top: 46px;
	margin-bottom: 60px;

	@media (min-width: $xl) {
		margin-bottom: 200px;
	}

	.section__title {
		font-size: 44px;
		margin-top: 31px;
		margin-bottom: 12px;

		@media (min-width: $lg) {
			font-size: $fs-title-l;
			margin-top: 0;
			margin-bottom: 20px;
		}
	}

	.light__title {
		color: var(--title-common)
	}

	.blink {
		background-color: var(--title-common);
		width: 2.5rem;
		height: 5px;
		border-radius: 1rem;
		display: inline-block;
		animation-name: blinker;
		animation-duration: 1.2s;
		animation-iteration-count: infinite;
		animation-timing-function: steps(2, start);
	}

	@keyframes blinker {
		to {
			visibility: hidden;
		}
	}

	.container__images {
		position: relative;
		width: 100%;

		@media (min-width: $lg) {
			width: auto;
		}

		.main__image {
			width: 100%;
			height: 100%;
			border-top-left-radius: 100px;
			border-bottom-right-radius: 100px;

			@media (min-width: $md) {
				border-top-left-radius: 180px;
				border-bottom-right-radius: 180px;
			}

			@media (min-width: $lg) {
				width: auto;
				height: auto;
				border-top-left-radius: 190px;
				border-bottom-right-radius: 190px;
			}
		}

		.lines__bottom {
			position: absolute;
			bottom: 15px;
			left: 15px;
			width: 20%;

			@media (min-width: $xl) {
				left: -80px;
				bottom: -75px;
				width: auto;
				height: auto;
			}
		}
	}
}