@use "../settings/variables" as *; 

#app {  
  --primary-color: var(--aquamarine); // aquamarine - deep
  --secondary-color: var(--deep-cove); // deep- turquoise dark mode
  --background-color: var(--alabaster); // alabaster - deep
  --text-color: var(--deep-cove); // deep - white
  --card-background: var(--white); // white - downriver
  --services-light-sections: var(--white); //white - deep
  --services-icon-light: var(--aquamarine);
  --services-dark-sections: var(--dark-sections);
  // links 
  --link-border: var(--deep-cove); // deep - white
  --link-arrow: var(--deep-cove); // deep - white
  
  //sections that keeps common styles light/dark mode
  --background-common: var(--downriver); // dark sections
  --title-common: var(--turquoise-dark-mode); // dark sections
  --text-common: var(--white); // dark sections
  --text-common-hover: var(--turquoise-dark-mode); // footer text hover
  
  --primary-common-color: var(--turquoise-dark-mode); // aquamarine
  --secondary-common-color: var(--deep-cove);  // deep

  --link-solid-text: var(--deep-cove);
  --link-solid-background: var(--turquoise-dark-mode);
  --link-solid-background-hover: var(--turquoise);

  background-color: var(--background-color);
  color: var(--text-color);
  font-family: $font-family;
  font-size: $fs-body;
  line-height: 1.5;
  margin: 0;
}

#app.dark{
  --primary-color: var(--deep-cove);
  --secondary-color: var(--turquoise-dark-mode);
  --background-color: var(--primary-color);
  --text-color: var(--white);
  --background-circle-icon: var(--turquoise-dark-mode);
  --card-background: var(--downriver);
  --services-light-sections: var(--deep-cove); //white - deep
  --services-icon-light: var(--deep-cove);
  // links 
  --link-border: var(--white);
  --link-arrow: var(--white);
}

::-moz-selection { /* Code for Firefox */
  color: var(--secondary-common-color);
  background: var(--primary-common-color);
}

::selection {
  color: var(--secondary-common-color);
  background: var(--primary-common-color);
}