@use "../../assets/styles/settings/variables" as *;

.contact {
	padding-top: 2.5rem;

	@media (min-width: $lg) {
		padding-top: 5rem;
	}

	.title__section {
		color: var(--secondary-color);
		margin-bottom: 60px;

		@media (min-width: $lg) {
			margin-bottom: 80px;
		}
	}

	.paragraph__light {
		font-weight: 300;
		margin: 0;

		&--margin {
			margin-bottom: 12px;
		}
	}

	.subtitle {
		margin-top: 32px;
		margin-bottom: 12px;
		font-family: $font-family-title;
		font-weight: 700;
		color: var(--secondary-color);
		font-size: 1.125rem;
	}

	.link__maps {
		font-weight: 500;
		font-size: 14px;
		text-decoration: underline;
	}

	.container__map {
		border-radius: 10px;
		width: 100%;
	}
}
