@use "../../assets/styles/settings/variables" as *;

.home__testimonials {
	margin-bottom: 60px;

	@media (min-width: $lg) {
		margin-bottom: 120px;
	}

	.title__section {
		font-size: 28px;
		margin-bottom: 40px;

		@media (min-width: $lg) {
			font-size: $fs-title;
			margin-bottom: 60px;
		}
	}

	.testimonial__image {
		margin-bottom: 20px;
		width: auto;
		height: auto;

		@media (min-width: $lg) {
			margin: 0;
		}
	}
}