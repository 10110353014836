@use "../../assets/styles/settings/variables" as *;

.button {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: $fs-body-s;
	padding: 16px;
	text-decoration: none;
	border-radius: 45px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 300ms ease-in-out;
	width: 100%;
	max-width: 350px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	white-space: nowrap;

	@media (min-width: $lg) {
		width: auto;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0%;
		height: 100%;
		transition: all .3s;
		border-radius: 45px;
		z-index: -1;
	}

	svg {
		margin-left: 12px;

		@media (min-width: $lg) {
			margin-left: 32px;
		}
	}

	// light -> no background, color & border = dark blue
	// light:hover -> background same as border, color = primary

	&--positive {
		color: var(--secondary-color);
		border: 1px solid var(--secondary-color);
		background-color: transparent;

		&:before {
			background-color: var(--secondary-color);
		}

		&:hover {
			color: var(--primary-color);
			border: 1px solid transparent;
			background-color: var(--secondary-color);

			&:before {
				width: 100%;
			}
		}
	}

	&--negative {
		color: var(--primary-color);
		border: 1px solid var(--primary-color);

		&:before {
			background-color: var(--primary-color);
		}

		&:hover {
			background-color: var(--primary-color);
			color: var(--text-color);
			border: 1px solid transparent;

			&:before {
				width: 100%;

			}
		}
	}

	&--solid {
		color: var(--link-solid-text);
		background: var(--link-solid-background);

		&:after {
			background-color: var(--link-solid-background);
		}

		&:before {
			background-color: var(--link-solid-background-hover);
		}

		&:focus {
			color: var(--link-solid-text);
		}

		&:hover {
			color: var(--link-solid-text);

			&:before {
				width: 100%;
			}
		}
	}

	&--common {
		border: 1px solid var(--title-common);
		color: var(--title-common);

		&:before {
			background-color: var(--title-common);
		}

		&:hover {
			background-color: var(--title-common);
			color: var(--background-common);
			border: 1px solid transparent;

			&:before {
				width: 100%;
			}
		}
	}
}

.toggle-button--light {
	display: grid;
	place-items: center;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: #f0f0f0;
	margin-right: 0;
	border: none;

	@media (min-width: $lg) {
		margin-right: 1.125rem;
	}
}

.burger-button {
	width: 30px;
	height: 24px;
	position: relative;
	cursor: pointer;
	display: block;

	.burger-item {
		height: 4px;
		background-color: var(--secondary-color);
		position: absolute;
		border-radius: 99px;
		transition: width 0.3s ease;
	}

	.burger-top {
		top: 0;
		width: 28px;
		animation: burger-top-initial 1s ease;
		animation-fill-mode: forwards;
	}

	.burger-mid {
		top: 50%;
		width: 19px;
		animation: burger-mid-initial 1s ease;
		animation-fill-mode: forwards;
	}

	.burger-bot {
		top: 100%;
		width: 24px;
		animation: burger-bottom-initial 1s ease;
		animation-fill-mode: forwards;
	}

	&:hover {

		.burger-top,
		.burger-mid,
		.burger-bot {
			width: 28px;
		}
	}

	@media (min-width: $lg) {
		display: none;
	}
}

.active {
	.burger-item {
		width: 28px;
	}

	.burger-top {
		animation: burger-top-rotate 1s ease;
		animation-fill-mode: forwards;
	}

	.burger-mid,
	.burger-bot {
		animation: burger-bottom-rotate 1s ease;
		animation-fill-mode: forwards;
	}
}


// ROTATE BURGER TO X
@keyframes burger-top-rotate {
	0% {
		top: start;
	}

	20% {
		top: 50%;
	}

	100% {
		top: 50%;
		transform: rotate(315deg);
	}
}

@keyframes burger-bottom-rotate {
	0% {
		top: start;
	}

	20% {
		top: 50%;
	}

	100% {
		top: 50%;
		transform: rotate(405deg);
	}
}

// ROTATE BURGER TO INITIAL STATE
@keyframes burger-top-initial {
	0% {
		top: 50%;
		transform: rotate(315deg);
	}

	20% {
		top: 50%;
	}

	100% {
		top: 0;
		width: 28px;
	}
}

@keyframes burger-mid-initial {
	0% {
		top: 50%;
		transform: rotate(405deg);
	}

	20% {
		top: 50%;
	}

	100% {
		top: 50%;
		width: 19px;
	}
}

@keyframes burger-bottom-initial {
	0% {
		top: 50%;
		transform: rotate(405deg);
	}

	20% {
		top: 50%;
	}

	100% {
		top: 100%;
		width: 24px;
	}
}

// MENU BURGER, FROM SAME SIZE TO DIFFERENT
@keyframes resizeBurger-top {
	from {
		width: 32px;
	}

	to {
		width: 28px;
	}
}

@keyframes resizeBurger-mid {
	from {
		width: 32px;
	}

	to {
		width: 19px;
	}
}

@keyframes resizeBurger-bot {
	from {
		width: 32px;
	}

	to {
		width: 24px;
	}
}

// HOVER BURGUER TO EQUALIZE BARS AT SAME WIDTH
@keyframes resizeBurgerBars {
	from {
		width: initial;
	}

	to {
		width: 24px;
	}
}

.dark {
	.toggle-button--light {
		background-color: #0a2d46;

		.toggle-light-dark {
			content: url(../../assets/images/dark-mode.svg);
		}
	}
}