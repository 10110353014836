@use "../../assets/styles/settings/variables" as *;
@use "../../assets/styles/tools/justify" as *;

.service {
  padding-block: 60px;

  @media (min-width: $lg) {
    padding-block: 100px;
  }

  .service__title {
    @include justify(center, flex-start);
    flex-wrap: nowrap;
    margin-bottom: 2rem;
  }

  .title__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    height: 70px;
    border-radius: 50%;

    img {
      width: auto;
      height: auto;
    }
  }

  .title__text {
    margin-bottom: 0;
    margin-left: 1rem;
    // padding-top: 10px;
    font-size: 1.75rem;

    @media (min-width: $lg) {
      font-size: 2.25rem;
    }
  }

  .list__title {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 1.25rem;

    @media (min-width: $lg) {
      font-size: 1.5rem;
    }
  }

  .list {
    list-style: disc;
    margin-left: 2rem;
    margin-bottom: 2.5rem;

    @media (min-width: $lg) {
      margin-bottom: 3.75rem;
    }

    .list__item {
      margin-bottom: 0.5rem;
      font-weight: 300;

      @media (min-width: $lg) {
        margin-bottom: 1rem;
      }
    }
  }

  .service__text {
    font-weight: 300;
    margin-bottom: 2rem;

    @media (min-width: $lg) {
      margin-bottom: 46px;
    }
  }

  .service__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    /* stylelint-disable */
    aspect-ratio: 7/5;
    /* stylelint-enable */
  }

  .play-wrapper {
    position: relative;

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #081f41 url(../../assets/images/play.svg) 55% center/40% no-repeat;
      width: 63px;
      height: 63px;
      border-radius: 50%;
      border: none;
      outline: rgba(0, 0, 0, 0.226) solid 10px;
      transition: all 300ms ease-in-out;
    }
  }

  .col-lg-5:last-of-type {
    margin-top: 40px;

    @media (min-width: $lg) {
      margin-top: 102px;
    }
  }

  &.light {
    background-color: var(--services-light-sections);

    .title__icon {
      background-color: var(--secondary-color);

      // .card__svg {
      //   path {
      //     fill: var(--services-icon-light);
      //   }
      // }
    }

    .service__text,
    .list {
      color: var(--text-color);
    }

    .title__text,
    .list__title,
    .related__name,
    .related__title {
      color: var(--secondary-color);
    }
  }

  &.dark {
    background-color: var(--services-dark-sections);

    .title__icon {
      background-color: var(--primary-common-color);

      // .card__svg {
      //   path {
      //     fill: var(--secondary-common-color);
      //   }
      // }
    }

    .service__text {
      color: var(--text-common);
    }

    .title__text,
    .list__title,
    .related__title,
    .related__name {
      color: var(--primary-common-color);
    }

    .list {
      color: var(--text-common);
    }
  }
}

.app-wrapper.dark {
  .icon--it {
    content: url("https://onvia.es/img/icons/it-icon-pri.svg");
  }

  .icon--web {
    content: url("https://onvia.es/img/icons/web-icon-pri.svg");
  }

  .icon--bpm {
    content: url("https://onvia.es/img/icons/bpm-icon-pri.svg");
  }

  .icon--dev {
    content: url("https://onvia.es/img/icons/dev-icon-pri.svg");
  }

  .icon--sell {
    content: url("https://onvia.es/img/icons/selligent-icon-pri.svg");
  }

  .icon--support {
    content: url("https://onvia.es/img/icons/it-icon-pri.svg");
  }
}