@use "../../assets/styles/settings/variables" as *;

.home-services {
	margin-bottom: 60px;

	@media (min-width: $lg) {
		margin-bottom: 120px;
	}

	.title__section {
		font-size: 28px;

		@media (min-width: $lg) {
			font-size: $fs-title;
		}
	}

	.button--positive {
		margin-top: 40px;

		@media (min-width: $lg) {
			margin-top: 60px;
		}
	}
}
