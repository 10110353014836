@use "../settings/variables";

h1,
h2,
h3,
h4, 
h5 {
  font-family: variables.$font-family-title;
  line-height: 1.4;
}

// TITLES
h1 {
  font-size: variables.$fs-title-l;
  font-weight: 500;
}

h2 {
  font-size: variables.$fs-title-m;
  font-weight: 600;
}

h3 {
  font-size: variables.$fs-title;
  font-weight: 500;
}

// SUBTITLES
h4 {
  font-size: variables.$fs-sub-title-m;
  font-weight: 500;
}

h5 {
  font-size: variables.$fs-sub-title;
  font-weight: 600;
}