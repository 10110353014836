@use "../../assets/styles/settings/variables" as *;

.web__footer {
  font-size: $fs-body-s;
  margin-top: 100px;

  .footer__title {
    margin-bottom: 2.1875rem;
  }

  .footer__item {
    margin-bottom: 2rem;

    &:last-of-type {
      @media (min-width: $lg) {
        margin-bottom: 0;
      }
    }
  }

  .footer__logo {
    height: 52px;
    display: block;
    margin: auto auto 60px auto;
    width: auto;

    @media (min-width: $lg) {
      display: inline;
      margin: auto;
    }
  }

  .footer__certificates {
    height: auto;
    width: 127px;
    margin-top: auto;

    @media (min-width: $lg) {
      width: 145px;
    }
  }

  .footer__cta-col {
    display: flex;
    justify-content: flex-start;

    @media (min-width: $lg) {
      justify-content: flex-end;
    }

    .button {
      margin-bottom: 1rem;
    }
  }

  .social__icon {
    color: var(--text-color);
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: flex-start;

    @media (min-width: $lg) {
      margin: 1rem 0.5rem 0 auto;
      justify-content: flex-end;
    }

    &:hover,
    &:focus {
      color: var(--text-common-hover);
    }
  }

  .footer__copyright {
    font-size: $fs-body-xs;
    padding-top: 0;
    padding-bottom: 24px;

    @media (min-width: $lg) {
      padding-top: 54px;
    }
  }
}

.dark {
  .footer__logo {
    content: url(../../assets/images/logo-dark.png);
  }
}