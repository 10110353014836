@use '../../assets/styles/settings/variables' as *;

.menu-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 100px;
  min-height: calc(100% - 108px);
  position: fixed;
  top: 108px;
  background-color: var(--background-color);
  z-index: 4;
  width: 100%;
  transform: translateX(-100%);
  transition: all 1s;

  &__link-list {
   list-style: none; 
   font-size: 20px;
    
    .link-list__item {
      margin-bottom: 40px;
      padding-left: 0;
      position: relative;
      transition: padding-left 0.3s ease;

      @media (max-height: 700px) {
        margin-bottom: 20px;
      }

      cursor: pointer;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--primary-common-color);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left:0;
        transform: translateY(-50%);
        opacity: 0;
      }

      &:hover,
      &.active {
        padding-left: 1.5rem;
        font-weight: bold;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .button--solid {
    align-self: center;
  }

  &.active {
    transform: translateX(0);
  }
  @media(min-width: $lg) {
    display: none;
  }
}

.no-scroll {
  overflow: hidden;
}