@use "../../assets/styles/settings/variables" as *;

.about-us__slider {

	.main__image {
		width: 100%;
		height: auto;
		border-top-left-radius: 100px;
		border-bottom-right-radius: 100px;

		@media (min-width: $md) {
			border-top-left-radius: 130px;
			border-bottom-right-radius: 130px;
		}

		@media (min-width: $lg) {
			width: auto;
			border-top-left-radius: 150px;
			border-bottom-right-radius: 150px;
		}
	}

	.swiper-pagination-bullet {
		background-color: var(--secondary-common-color);
	}

}