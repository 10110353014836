@use '../settings/variables' as *;

a {
  text-decoration: none;
  color: var(--text-color);
  transition: 300ms;

  &:hover,
  &:focus {
    color: var(--text-common-hover);
  }
}